body {
  height: 100vh;
  padding: 0;
  background-color: #000;
}

h1 {
  font-size: calc(10px + 2vmin);
  color: white;
}

p {
  color: white;
}

.App {
  background-color: #000;
  width: 100%;
  height: 100%;
    margin: auto;
}

.App-logo {
  height: 40vmin;
  border-width: 1px;
  border-style: solid;
  border-color: #444;
}


.App-link {
  color: #61dafb;
}

.left {
  margin-top: 20px;
}

.rss-icon {
  max-width: 25px;
}